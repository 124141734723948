.menu-items {
    flex-direction: row;
    font-size: 18px;
}

.menu-item {
    color: black;
    text-decoration: none;
    padding: 10px;
}

.menu-item-selected {
    color: var(--main-background-color);
}

.menu-howDoesItWork {
    color: black;
    font-size: 20px
}

.menu-item:hover,
.menu-howDoesItWork:hover {
    color: gray;
}

.menu-button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .menu-items {
        flex-direction: column;
    }

    .menu-howDoesItWork {
        display: none;
    }
}