:root {
  --main-background-color: rgb(42 159 75);
  --main-text-color: #ffffff;
  --primary-text-color: #000000;
  --main-shadow: 0px 6px 20px rgba(59, 76, 106, .13), 0px 1.34018px 4.46726px rgba(59, 76, 106, .0774939), 0px .399006px 1.33002px rgba(59, 76, 106, .0525061);
}

html,
body,
#root {
  overflow-y: unset;
}

body {
  margin: 0;
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_container {
  display: grid;
}

h1,
h2,
h3,
span,
label {
  color: var(--main-text-color);
  /*text-shadow: var(--main-shadow);*/
}

h2 {
  font-size: 26px;
}

canvas {
  border-radius: 12px;
  box-shadow: var(--main-shadow);
  background-color: hsla(219, 28%, 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
