.form-button {
    background-color: white;
    color: var(--main-background-color);
    padding: .6em 1em;
    border: 1px solid var(--main-background-color);
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-button-delete {
    background-color: red;
    color: white;
    border: 1px solid #e3d3d3;
}

.form-button-raw {
    background-color: white;
    color: var(--main-background-color);
    border: 1px solid var(--main-background-color);
    border-radius: 5px;
    font-size: 1rem;
}

.form-button-raw:hover,
.form-button:hover {
    cursor: pointer;
    background-color: var(--main-background-color);
    color: white;
}

.form-button-delete:hover {
    background-color: crimson;
}

.form-field {
    box-sizing: border-box;
    display: flex;
    position: relative;
    color: black;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.form-field > input[type=text]:focus {
    font-size: 1rem;
    border: 3px solid #555;
}

.form-list {
    position: relative;
    border: 1px solid var(--main-background-color);
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.form-list > label,
.form-field > label {
    font-weight: bold;
    color: var(--main-background-color);
    position: absolute;
    font-size: .825rem;
    margin-top: -4px;
    margin-left: 0.5rem;
    background: linear-gradient(white 7px, transparent 0, transparent);
    display: inline-block;
    padding: 0 0.3rem;
    z-index: 1;
    line-height: 1em;
    white-space: nowrap;
}

.form-field > input:not([type=checkbox]):not([type=radio]),
.form-field > select,
.form-field > textarea {
    width: 100%;
    padding: 0.7rem 0.5rem 0.5rem;
    font-family: Open Sans, sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-field > button,
.form-field > textarea,
.form-field > input:not([type=checkbox]):not([type=radio]), select {
    border: 1px solid var(--main-background-color);
    border-radius: 4px;
    color: inherit;
    background: white;
    font-size: .85rem;
}

.form-button:disabled,
.form-button-raw:disabled,
.form-field > button:disabled,
.form-field > textarea:disabled,
.form-field > select:disabled,
.form-field > input:disabled {
    background: #cfcfcf4a !important;
}

.form-field > input:required:invalid {
    color: red !important;
}

.form-button:disabled,
.form-button-raw:disabled {
    border: 1px solid #4066b09c;
}

.form-field > textarea {
    min-height: 150px;
    resize: none;
}

.form-field > input:disabled {
    color: gray !important;
}


.windowListDiv,
.windowListDivSelected {
    border-radius: 3px;
    margin: 1px;
    padding: 2px;
    font-size: 0.85rem;
    cursor: default;
}

.windowListDivSelected {
    background-color: #0e6dd3;
    color: white;
}

.form-input_field-and-button {
    display: grid;
    grid-template-columns: auto 100px;
}
.form-input_field-and-button > button {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.form-line {
    display: block;
    border: 1px solid var(--main-background-color);
}

.form-padding {
    padding-left: 5px;
    padding-right: 5px;
}

.form-button-left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.form-button-right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}